import Vue from "vue";

export async function index() {
  const response = await Vue.prototype.$http.get(
    `/portal_companhias/insurers`
  );

  return response.data;
}

export async function show(id) {
  const response = await Vue.prototype.$http.get(
      `/portal_companhias/insurers/${id}`
  );

  return response.data
}