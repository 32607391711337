var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-breadcrumbs',{attrs:{"items":[
        {
            text: 'portal companhias',
            disabled: true
        },
        {
            text: _vm.insurer.name,
            to: {
                name: 'hcm_universe.portal_companhias'
            },
            exact: true
        },
        {
            text: 'utilizadores',
            disabled: true
        }
    ]}}),_c('v-card',[_c('v-card-title',[_vm._v(" Utilizadores ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Buscar utilizador","prepend-inner-icon":"mdi-magnify","solo":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary","disabled":"","title":"Funcionalidade em desenvolvimento"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Adicionar ")],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.search,"loading":_vm.loading},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.is_active",fn:function({item}){return [(item.is_active)?_c('v-chip',{attrs:{"color":"green","dark":"","small":"","label":""}},[_vm._v(" Ativo ")]):_c('v-chip',{attrs:{"color":"red","dark":"","small":"","label":""}},[_vm._v(" Bloqueado ")])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }