<template>
    <v-container>
        <v-breadcrumbs :items="[
            {
                text: 'portal companhias',
                disabled: true
            },
            {
                text: insurer.name,
                to: {
                    name: 'hcm_universe.portal_companhias'
                },
                exact: true
            },
            {
                text: 'utilizadores',
                disabled: true
            }
        ]"></v-breadcrumbs>

        <v-card>
            <v-card-title>
                Utilizadores
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <v-text-field 
                            v-model="search"
                            label="Buscar utilizador" 
                            prepend-inner-icon="mdi-magnify"
                            solo 
                            single-line 
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn color="primary" disabled title="Funcionalidade em desenvolvimento">
                            <v-icon left>mdi-plus</v-icon> Adicionar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-data-table :headers="headers" :items="users" :search.sync="search" :loading="loading">
                <template v-slot:item.is_active="{item}">
                    <v-chip v-if="item.is_active" color="green" dark small label>
                        Ativo
                    </v-chip>
                    <v-chip v-else color="red" dark small label>
                        Bloqueado
                    </v-chip>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import {show} from "@/services/PortalCompanhias/insurers";
import {index} from "@/services/PortalCompanhias/users";

export default {
    props: {
        id: [Number,String]
    },

    data() {
        return {
            search: "",
            headers: [
                {
                    text: 'Nome',
                    value: 'name'
                },
                {
                    text: 'Email',
                    value: 'email'
                },
                {
                    text: 'Status',
                    value: 'is_active'
                }
            ],
            users: [],
            insurer: {},
            loading: false
        }
    },

    methods: {
        async getInsurerData() {
            this.loading = true;

            try {
                const response = await show(this.id);

                this.insurer = response.data;
            } catch(error) {

            } finally {
                this.loading = false;
            }
        },
        async getUserData() {
            this.loading = true;

            try {
                const response = await index(this.id, this.search);

                this.users = response;
            } catch(error) {

            } finally {
                this.loading = false;
            }
        }
    },

    created() {
        this.getInsurerData();
        this.getUserData();
    }
}
</script>