import Vue from "vue";

export async function index(domain, search) {
  const response = await Vue.prototype.$http.get(
    `/portal_companhias/${domain}/users`,
    {
        params: {
            search
        }
    }
  );

  return response.data;
}